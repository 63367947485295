import axios from "axios";
import config from "../config";

export function generateScript(data) {
  return axios.post(`${config.api_url}/moh/script`, data);
}

export function getVoices() {
  return axios.get(`${config.api_url}/moh/voices`);
}

export function getVoicePreview(voice) {
  return axios.get(`${config.api_url}/moh/voice-preview/${voice}`);
}

export function generateFile(data) {
  return axios.post(`${config.api_url}/moh/generate`, data);
}

export function uploadIpecs(data) {
  return axios.post(`${config.api_url}/moh/upload`, data);
}
